import { AxiosInstance } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { Affiliation } from '@modules/affiliations/types/Affiliations/affiliations';
import {
  CreateRiderAffiliationRequest,
  DeleteRiderAffiliationByIdRequest,
  FindRiderAffiliationsPaginatedRequest,
  SendNotificationRiderAffiliationPaymentPendingRequest,
  UpdateRiderAffiliationRequest,
} from '@modules/affiliations/types/Affiliations/requests';

export class AssociationRidersAffiliationsService {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Riders affiliations ----------

  async create(data: CreateRiderAffiliationRequest): Promise<Affiliation> {
    const { associationRiderId: riderId, ...body } = data;

    const response = await this.instance.post(`/associations/riders/${riderId}/affiliations`, body);

    return response.data;
  }

  async delete(data: DeleteRiderAffiliationByIdRequest): Promise<void> {
    const { associationRiderId, affiliationId } = data;

    await this.instance.delete(`/associations/riders/${associationRiderId}/affiliations/${affiliationId}`);
  }

  async findPaginated(data: FindRiderAffiliationsPaginatedRequest): Promise<IPaginateDTO<Affiliation>> {
    const { associationRiderId: riderId, ...params } = data;

    const response = await this.instance.get(`/associations/riders/${riderId}/affiliations`, { params });

    return response.data;
  }

  async update(data: UpdateRiderAffiliationRequest): Promise<Affiliation> {
    const { associationRiderId: riderId, affiliationId, ...body } = data;

    const response = await this.instance.put(`/associations/riders/${riderId}/affiliations/${affiliationId}`, body);

    return response.data;
  }

  async notifyPendingPayment(data: SendNotificationRiderAffiliationPaymentPendingRequest): Promise<void> {
    const { associationRiderId: riderId, affiliationId, ...body } = data;

    const url = `/associations/riders/${riderId}/affiliations/${affiliationId}/notify/pending-payment`;

    await this.instance.post(url, body);
  }
}

export const associationRidersAffiliationsService = (instance: AxiosInstance): AssociationRidersAffiliationsService =>
  new AssociationRidersAffiliationsService(instance);
