import { AxiosInstance, AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';

import { openPdf } from '@shared/utils/OpenPDF';

import { IBalancesReportRequest } from '@modules/balances/types/Balances/requests';
import { IReportBalancesByCategories, IReportEarningsExpenses } from '@modules/balances/types/Reports/reports';
import {
  IGetReportBalancesByCategoriesRequest,
  IGetReportEarningsExpensesDailyRequest,
  IGetReportEarningsExpensesMonthlyRequest,
} from '@modules/balances/types/Reports/requests';

export class BalancesReportsService {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Balances ----------

  async exportBalancesPdf(params: IBalancesReportRequest): Promise<AxiosResponse<void>> {
    const url = `/balances/report/pdf/export`;
    const { data, ...response } = await this.instance.get(url, { params, responseType: 'blob' });

    openPdf(data);

    return { data, ...response };
  }

  async exportBalancesCsv(params: IBalancesReportRequest): Promise<AxiosResponse<void>> {
    const url = `/balances/report/csv/export`;

    const { data, ...response } = await this.instance.get(url, { params });

    fileDownload(data, `balances-${params.from}-to-${params.to}.csv`);

    return { data, ...response };
  }

  async exportBalancesXlsx(params: IBalancesReportRequest): Promise<AxiosResponse<void>> {
    const url = `/balances/report/xlsx/export`;

    const { data, ...response } = await this.instance.get(url, { params, responseType: 'blob' });

    fileDownload(data, `balances-${params.from}-to-${params.to}.xlsx`);

    return { data, ...response };
  }

  // ---------- Balances categories ----------

  async getReportBalancesByCategories(
    params: IGetReportBalancesByCategoriesRequest,
  ): Promise<AxiosResponse<IReportBalancesByCategories>> {
    return this.instance.get('/balances/categories/report', { params });
  }

  async exportBalancesByCategoriesPdf(params: IGetReportBalancesByCategoriesRequest): Promise<AxiosResponse<void>> {
    const url = `/balances/categories/report/pdf/export`;

    const { data, ...response } = await this.instance.get(url, { params, responseType: 'blob' });

    openPdf(data);

    return { data, ...response };
  }

  async exportBalancesByCategoriesCsv(params: IGetReportBalancesByCategoriesRequest): Promise<AxiosResponse<void>> {
    const url = `/balances/categories/report/csv/export`;
    const { data, ...response } = await this.instance.get(url, { params });

    fileDownload(data, `balances-${params.from}-to-${params.to}.csv`);

    return { data, ...response };
  }

  // ---------- Balances earnings and expenses ----------

  async getReportEarningsExpensesDaily(
    params: IGetReportEarningsExpensesDailyRequest,
  ): Promise<AxiosResponse<IReportEarningsExpenses[]>> {
    return this.instance.get('/balances/earnings-expenses/daily/report', { params });
  }

  async exportEarningsExpensesDailyPdf(params: IGetReportEarningsExpensesDailyRequest): Promise<AxiosResponse<void>> {
    const url = `/balances/earnings-expenses/daily/report/pdf/export`;
    const { data, ...response } = await this.instance.get(url, { params, responseType: 'blob' });

    openPdf(data);

    return { data, ...response };
  }

  async exportEarningsExpensesDailyCsv(params: IGetReportEarningsExpensesDailyRequest): Promise<AxiosResponse<void>> {
    const url = `/balances/earnings-expenses/daily/report/csv/export`;
    const { data, ...response } = await this.instance.get(url, { params });

    fileDownload(data, `earnings-expenses-daily-${params.from}-to-${params.to}.csv`);

    return { data, ...response };
  }

  async getReportEarningsExpensesMonthly(
    params: IGetReportEarningsExpensesMonthlyRequest,
  ): Promise<AxiosResponse<IReportEarningsExpenses[]>> {
    return this.instance.get('/balances/earnings-expenses/monthly/report', { params });
  }

  async exportEarningsExpensesMonthlyPdf(
    params: IGetReportEarningsExpensesMonthlyRequest,
  ): Promise<AxiosResponse<void>> {
    const url = `/balances/earnings-expenses/monthly/report/pdf/export`;
    const { data, ...response } = await this.instance.get(url, { params, responseType: 'blob' });

    openPdf(data);

    return { data, ...response };
  }

  async exportEarningsExpensesMonthlyCsv(
    params: IGetReportEarningsExpensesMonthlyRequest,
  ): Promise<AxiosResponse<void>> {
    const url = `/balances/earnings-expenses/monthly/report/csv/export`;
    const { data, ...response } = await this.instance.get(url, { params });

    fileDownload(data, `earnings-expenses-monthly-${params.date}.csv`);

    return { data, ...response };
  }
}

export const balancesReportsService = (instance: AxiosInstance): BalancesReportsService =>
  new BalancesReportsService(instance);
