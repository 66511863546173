import { AxiosInstance, AxiosResponse } from 'axios';

import {
  IGetCurrentProfileResponse,
  IUpdateCurrentProfileAvatarRequest,
  IUpdateCurrentProfileAvatarResponse,
  IUpdateCurrentProfileCompanyRequest,
  IUpdateCurrentProfileCompanyResponse,
  IUpdateCurrentProfileIdiomAndRegionRequest,
  IUpdateCurrentProfileIdiomAndRegionResponse,
} from '@modules/profiles/types/Profile/requests';

export class ProfileService {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getCurrentProfile(): Promise<AxiosResponse<IGetCurrentProfileResponse>> {
    return this.instance.get(`/profiles/me`);
  }

  updateCurrentProfileAvatar(
    data: IUpdateCurrentProfileAvatarRequest,
  ): Promise<AxiosResponse<IUpdateCurrentProfileAvatarResponse>> {
    const { file } = data;

    const formData = new FormData();
    formData.append('avatar', file);

    return this.instance.patch(`/profiles/me/avatar`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
  }

  updateCurrentProfileCompany(
    data: IUpdateCurrentProfileCompanyRequest,
  ): Promise<AxiosResponse<IUpdateCurrentProfileCompanyResponse>> {
    return this.instance.patch(`/profiles/me/company`, data);
  }

  updateCurrentProfileIdiomAndRegion(
    data: IUpdateCurrentProfileIdiomAndRegionRequest,
  ): Promise<AxiosResponse<IUpdateCurrentProfileIdiomAndRegionResponse>> {
    return this.instance.patch(`/profiles/me/idiom-and-region`, data);
  }
}

export const profileService = (instance: AxiosInstance): ProfileService => new ProfileService(instance);
