import { AxiosInstance } from 'axios';
import fileDownload from 'js-file-download';

import { ExportAssociationRidersRequest } from '@modules/riders/types/AssociationRiders/requests';

export class AssociationsRidersReportsService {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Export ----------

  public async exportXlsx(params: ExportAssociationRidersRequest): Promise<void> {
    const url = `/associations/riders/reports/summary/xlsx/export`;

    const { data } = await this.instance.get(url, { params, responseType: 'blob' });

    fileDownload(data, `riders.xlsx`);
  }
}

export const associationRidersReportsService = (instance: AxiosInstance): AssociationsRidersReportsService =>
  new AssociationsRidersReportsService(instance);
