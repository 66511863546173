import { AxiosInstance, AxiosResponse } from 'axios';

import { IGetCurrentProfileAboutResponse } from '@modules/profiles/types/About/requests';

export class ProfileAboutService {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getCurrentProfileAbout(): Promise<AxiosResponse<IGetCurrentProfileAboutResponse>> {
    return this.instance.get(`/profiles/about`);
  }
}

export const profileAboutService = (instance: AxiosInstance): ProfileAboutService => new ProfileAboutService(instance);
