import { AxiosInstance } from 'axios';

import { IBalanceCategory } from '@modules/balances/types/Categories/categories';
import {
  ICreateBalanceCategoryRequest,
  IDeleteBalanceCategoryRequest,
  IUpdateBalanceCategoryRequest,
} from '@modules/balances/types/Categories/requests';

export class BalancesCategoriesService {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Categories ----------

  async create(data: ICreateBalanceCategoryRequest): Promise<IBalanceCategory> {
    const response = await this.instance.post(`/balances/categories`, data);

    return response.data;
  }

  async delete({ categoryId }: IDeleteBalanceCategoryRequest): Promise<void> {
    await this.instance.delete(`/balances/categories/${categoryId}`);
  }

  async getAll(): Promise<IBalanceCategory[]> {
    const response = await this.instance.get(`/balances/categories`);

    return response.data;
  }

  async update(data: IUpdateBalanceCategoryRequest): Promise<IBalanceCategory> {
    const { categoryId, ...body } = data;

    const response = await this.instance.patch(`/balances/categories/${categoryId}`, body);

    return response.data;
  }
}

export const balancesCategoriesService = (instance: AxiosInstance): BalancesCategoriesService =>
  new BalancesCategoriesService(instance);
