import { AxiosInstance, AxiosResponse } from 'axios';

import { IChangePasswordRequest } from '@modules/auth/types/Password/requests';

export class PasswordService {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  changePassword(data: IChangePasswordRequest): Promise<AxiosResponse<void>> {
    return this.instance.post(`/change-password`, data);
  }
}

export const passwordService = (instance: AxiosInstance): PasswordService => new PasswordService(instance);
