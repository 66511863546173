export function removeSw() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister();
      });
    }).catch(error => {
      throw new Error('Error unregistering service worker', error);
    });
  }
}
