import { AxiosInstance } from 'axios';

import { Configuration as ConfigurationEntity } from '@modules/configurations/types/Configurations/configurations';
import {
  UpdateConfigurationsRequest,
  UpdateImageReportsRequest,
  UpdateImageReportsResponse,
} from '@modules/configurations/types/Configurations/requests';

export class ConfigurationService {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async getConfigurations(): Promise<ConfigurationEntity> {
    const response = await this.instance.get(`/configurations`);

    return response.data;
  }

  async updateConfigurations(data: UpdateConfigurationsRequest): Promise<ConfigurationEntity> {
    const response = await this.instance.patch(`/configurations`, data);

    return response.data;
  }

  async updateImageReports(data: UpdateImageReportsRequest): Promise<UpdateImageReportsResponse> {
    const formData = new FormData();
    formData.append('image', data.file);

    const headers = { 'Content-Type': 'multipart/form-data' };

    const response = await this.instance.put(`/configurations/image-reports`, formData, { headers });

    return response.data;
  }

  async updateCompanyRegulation(data: UpdateImageReportsRequest): Promise<void> {
    const formData = new FormData();
    formData.append('regulation', data.file);

    const headers = { 'Content-Type': 'multipart/form-data' };

    await this.instance.put(`/configurations/regulations/athlete-create-appointment-request`, formData, { headers });
  }

  async deleteCompanyRegulation(): Promise<void> {
    await this.instance.delete(`/configurations/regulations/athlete-create-appointment-request`);
  }
}

export const configurationService = (instance: AxiosInstance): ConfigurationService =>
  new ConfigurationService(instance);
