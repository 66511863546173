import { AxiosInstance, AxiosResponse } from 'axios';

import { IGetCurrentProfileCompanyDataResponse } from '@modules/profiles/types/CompanyData/requests';

export class ProfileCompanyDataService {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // Profile

  getCurrentProfileCompanyData(): Promise<AxiosResponse<IGetCurrentProfileCompanyDataResponse>> {
    return this.instance.get(`/profiles/company-data`);
  }
}

export const profileCompanyDataService = (instance: AxiosInstance): ProfileCompanyDataService =>
  new ProfileCompanyDataService(instance);
