import { AxiosInstance } from 'axios';

import {
  FindAssociationRiderModalitiesCategoriesRequest,
  UpdateAssociationRiderModalitiesCategoriesRequest,
} from '@modules/riders/types/AssociationRiders/requests';
import {
  IAssociationRiderModalityCategory,
  IAssociationRiderModalityCategoryAvailable,
} from '@modules/riders/types/AssociationRiders/riders';

export class AssociationsRidersCategoriesService {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Modalities categories ----------

  public async findModalitiesCategories({
    riderId,
  }: FindAssociationRiderModalitiesCategoriesRequest): Promise<IAssociationRiderModalityCategory[]> {
    const response = await this.instance.get(`/associations/riders/${riderId}/modalities-categories`);

    return response.data;
  }

  public async update(
    data: UpdateAssociationRiderModalitiesCategoriesRequest,
  ): Promise<IAssociationRiderModalityCategory[]> {
    const { riderId, ...body } = data;

    const response = await this.instance.put(`/associations/riders/${riderId}/modalities-categories`, body);

    return response.data;
  }

  // ---------- Modalities categories available ----------

  public async findModalitiesCategoriesAvailable({
    riderId,
  }: FindAssociationRiderModalitiesCategoriesRequest): Promise<IAssociationRiderModalityCategoryAvailable> {
    const response = await this.instance.get(`/associations/riders/${riderId}/modalities-categories-available`);

    return response.data;
  }
}

export const associationRidersCategoriesService = (instance: AxiosInstance): AssociationsRidersCategoriesService =>
  new AssociationsRidersCategoriesService(instance);
