import { AxiosInstance } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import {
  AffiliationRider,
  AssociationRiderFiliationDetail,
} from '@modules/affiliations/types/Affiliations/affiliations';
import {
  FindAffiliationsRidersPaginatedRequest,
  FindRiderFiliationDetailRequest,
} from '@modules/affiliations/types/Affiliations/requests';

export class AffiliationsService {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Affiliations ----------

  async getAffiliationsRiders(params: FindAffiliationsRidersPaginatedRequest): Promise<IPaginateDTO<AffiliationRider>> {
    const response = await this.instance.get(`/associations/filiations/riders`, { params });

    return response.data;
  }

  async getAffiliationsRiderDetail(data: FindRiderFiliationDetailRequest): Promise<AssociationRiderFiliationDetail> {
    const response = await this.instance.get(`/associations/filiations/riders/${data.associationRiderId}`);

    return response.data;
  }
}

export const affiliationsService = (instance: AxiosInstance): AffiliationsService => new AffiliationsService(instance);
