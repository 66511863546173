import { AxiosInstance } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { AssociationHorseByCompanySlug } from '@modules/horses/types/AssociationHorsesPublic/public';
import { FindAssociationHorsesByCompanySlugRequest } from '@modules/horses/types/AssociationHorsesPublic/requests';

type FindResponse = Promise<IPaginateDTO<AssociationHorseByCompanySlug>>;

export class AssociationsHorsesPublicService {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Horses ----------

  public async find(data: FindAssociationHorsesByCompanySlugRequest): FindResponse {
    const { slug, ...params } = data;

    const response = await this.instance.get(`/public/associations/${slug}/horses`, { params });

    return response.data;
  }
}

export const associationHorsesPublicService = (instance: AxiosInstance): AssociationsHorsesPublicService =>
  new AssociationsHorsesPublicService(instance);
