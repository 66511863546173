import { AxiosInstance } from 'axios';

import {
  DashboardHomeBirthdaysMonth,
  DashboardHomeEntities,
  DashboardHomeFiliation,
  DashboardHomeSummaryNumbers,
} from '@modules/dashboard/types/DashboardHome/dashboard';
import {
  GetDashboardHomeBirthdaysMonthRequest,
  GetDashboardHomeFiliationsRequest,
} from '@modules/dashboard/types/DashboardHome/requests';

export class AssociationDashboardService {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Home - Birthdays ----------

  public async getHomeBirthdays(params: GetDashboardHomeBirthdaysMonthRequest): Promise<DashboardHomeBirthdaysMonth[]> {
    const response = await this.instance.get(`/associations/dashboard/home/birthdays`, { params });

    return response.data;
  }

  // ---------- Home - Entities ----------

  public async getHomeEntities(): Promise<DashboardHomeEntities> {
    const response = await this.instance.get(`/associations/dashboard/home/entities`);

    return response.data;
  }

  // ---------- Home - Filiations ----------

  public async getHomeFiliations(params: GetDashboardHomeFiliationsRequest): Promise<DashboardHomeFiliation[]> {
    const response = await this.instance.get(`/associations/dashboard/home/filiations`, { params });

    return response.data;
  }

  // ---------- Home - Summary numbers ----------

  public async getHomeSummaryNumbers(): Promise<DashboardHomeSummaryNumbers> {
    const response = await this.instance.get(`/associations/dashboard/home/summary-numbers`);

    return response.data;
  }
}

export const associationDashboardService = (instance: AxiosInstance): AssociationDashboardService =>
  new AssociationDashboardService(instance);
