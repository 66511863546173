import { AxiosInstance } from 'axios';

import { IModality } from '@modules/modalities/types/Modalities/modalities';

export class ModalitiesService {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  async findAll(): Promise<IModality[]> {
    const response = await this.instance.get(`/associations/modalities`);

    return response.data;
  }
}

export const modalitiesService = (instance: AxiosInstance): ModalitiesService => new ModalitiesService(instance);
