import { Persons, persons } from '@modules/persons/services/Persons';
import { AxiosInstance } from 'axios';

import { IClientConfigProps } from '@shared/clients/types/types';

import { addressesService, AddressesService } from '@modules/addresses/services/Addresses';

import { AffiliationsService, affiliationsService } from '@modules/affiliations/services/Affiliations';
import {
  AssociationHorsesAffiliationsService,
  associationHorsesAffiliationsService,
} from '@modules/affiliations/services/HorsesAffiliations';
import {
  AssociationRidersAffiliationsService,
  associationRidersAffiliationsService,
} from '@modules/affiliations/services/RidersAffiliations';

import { balancesService, BalancesService } from '@modules/balances/services/Balances';
import { BalancesCategoriesService, balancesCategoriesService } from '@modules/balances/services/Categories';
import { balancesDashboardService, BalancesDashboardService } from '@modules/balances/services/Dashboard';
import { balancesReportsService, BalancesReportsService } from '@modules/balances/services/Reports';
import { balancesWalletsService, BalancesWalletsService } from '@modules/balances/services/Wallets';

import { associationCompaniesService, AssociationCompaniesService } from '@modules/companies/services/Companies';
import {
  AssociationCompaniesPublicService,
  associationCompaniesPublicService,
} from '@modules/companies/services/CompaniesPublic';

import { configurationService, ConfigurationService } from '@modules/configurations/services/Configurations';
import { myTeamService, MyTeamService } from '@modules/configurations/services/MyTeam';

import { AssociationDashboardService, associationDashboardService } from '@modules/dashboard/services/Dashboard';

import { associationHorsesService, AssociationsHorsesService } from '@modules/horses/services/AssociationsHorses';
import {
  associationHorsesIdentificationsService,
  AssociationHorsesIdentificationsService,
} from '@modules/horses/services/AssociationsHorsesIdentifications';
import {
  AssociationHorsesPaymentsService,
  associationHorsesPaymentsService,
} from '@modules/horses/services/AssociationsHorsesPayments';
import {
  associationHorsesPublicService,
  AssociationsHorsesPublicService,
} from '@modules/horses/services/AssociationsHorsesPublic';
import {
  associationHorsesReportsService,
  AssociationsHorsesReportsService,
} from '@modules/horses/services/AssociationsHorsesReports';
import {
  associationHorsesStampsService,
  AssociationHorsesStampsService,
} from '@modules/horses/services/AssociationsHorsesStamps';

import { invoicesService, InvoicesService } from '@modules/invoices/services/Invoices';

import { modalitiesService, ModalitiesService } from '@modules/modalities/services/Modalities';
import {
  modalitiesCategoriesService,
  ModalitiesCategoriesService,
} from '@modules/modalities/services/ModalitiesCategories';

import { ProfileAboutService, profileAboutService } from '@modules/profiles/services/About';
import { profileCompanyDataService, ProfileCompanyDataService } from '@modules/profiles/services/CompanyData';
import { profileService, ProfileService } from '@modules/profiles/services/Profile';

import { associationRidersService, AssociationsRidersService } from '@modules/riders/services/AssociationRiders';
import {
  associationRidersCategoriesService,
  AssociationsRidersCategoriesService,
} from '@modules/riders/services/AssociationRidersCategories';
import {
  associationRidersPaymentsService,
  AssociationRidersPaymentsService,
} from '@modules/riders/services/AssociationRidersPayments';
import {
  associationRidersPublicService,
  AssociationsRidersPublicService,
} from '@modules/riders/services/AssociationRidersPublic';
import {
  associationRidersReportsService,
  AssociationsRidersReportsService,
} from '@modules/riders/services/AssociationRidersReports';

import { companiesApiConfig } from './config';

export class CompaniesAPI {
  private instance: AxiosInstance;

  constructor(params: IClientConfigProps) {
    this.instance = companiesApiConfig(params).instance();
  }

  addresses(): AddressesService {
    return addressesService(this.instance);
  }

  affiliations(): AffiliationsService {
    return affiliationsService(this.instance);
  }

  associationCompanies(): AssociationCompaniesService {
    return associationCompaniesService(this.instance);
  }

  associationCompaniesPublic(): AssociationCompaniesPublicService {
    return associationCompaniesPublicService(this.instance);
  }

  associationDashboard(): AssociationDashboardService {
    return associationDashboardService(this.instance);
  }

  associationHorses(): AssociationsHorsesService {
    return associationHorsesService(this.instance);
  }

  associationHorsesAffiliations(): AssociationHorsesAffiliationsService {
    return associationHorsesAffiliationsService(this.instance);
  }

  associationHorsesIdentifications(): AssociationHorsesIdentificationsService {
    return associationHorsesIdentificationsService(this.instance);
  }

  associationHorsesPayments(): AssociationHorsesPaymentsService {
    return associationHorsesPaymentsService(this.instance);
  }

  associationHorsesPublic(): AssociationsHorsesPublicService {
    return associationHorsesPublicService(this.instance);
  }

  associationHorsesReports(): AssociationsHorsesReportsService {
    return associationHorsesReportsService(this.instance);
  }

  associationHorsesStamps(): AssociationHorsesStampsService {
    return associationHorsesStampsService(this.instance);
  }

  associationPersons(): Persons {
    return persons(this.instance);
  }

  associationRiders(): AssociationsRidersService {
    return associationRidersService(this.instance);
  }

  associationRidersAffiliations(): AssociationRidersAffiliationsService {
    return associationRidersAffiliationsService(this.instance);
  }

  associationRidersCategories(): AssociationsRidersCategoriesService {
    return associationRidersCategoriesService(this.instance);
  }

  associationRidersPayments(): AssociationRidersPaymentsService {
    return associationRidersPaymentsService(this.instance);
  }

  associationRidersPublic(): AssociationsRidersPublicService {
    return associationRidersPublicService(this.instance);
  }

  associationRidersReports(): AssociationsRidersReportsService {
    return associationRidersReportsService(this.instance);
  }

  balances(): BalancesService {
    return balancesService(this.instance);
  }

  balancesCategories(): BalancesCategoriesService {
    return balancesCategoriesService(this.instance);
  }

  balancesDashboard(): BalancesDashboardService {
    return balancesDashboardService(this.instance);
  }

  balancesReports(): BalancesReportsService {
    return balancesReportsService(this.instance);
  }

  balancesWallets(): BalancesWalletsService {
    return balancesWalletsService(this.instance);
  }

  configurations(): ConfigurationService {
    return configurationService(this.instance);
  }

  invoices(): InvoicesService {
    return invoicesService(this.instance);
  }

  modalities(): ModalitiesService {
    return modalitiesService(this.instance);
  }

  modalitiesCategories(): ModalitiesCategoriesService {
    return modalitiesCategoriesService(this.instance);
  }

  myTeam(): MyTeamService {
    return myTeamService(this.instance);
  }

  profiles(): ProfileService {
    return profileService(this.instance);
  }

  profilesAbout(): ProfileAboutService {
    return profileAboutService(this.instance);
  }

  profilesCompanyData(): ProfileCompanyDataService {
    return profileCompanyDataService(this.instance);
  }
}

export const CompaniesFactoryAPI = (params: IClientConfigProps): CompaniesAPI => new CompaniesAPI(params);
