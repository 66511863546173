import { AxiosInstance } from 'axios';

import { IClientConfigProps } from '@shared/clients/types/types';

import { passwordService, PasswordService } from '@modules/auth/services/Password';

import { groupsPermissionsService, GroupsPermissionsService } from '@modules/configurations/services/GroupPermissions';
import { MyTeamService, myTeamService } from '@modules/configurations/services/MyTeam';
import { permissionsService, PermissionsService } from '@modules/configurations/services/Permissions';

import { identityApiConfig } from './config';

export class IdentityAPI {
  private instance: AxiosInstance;

  constructor(params: IClientConfigProps) {
    this.instance = identityApiConfig(params).instance();
  }

  groupsPermissions(): GroupsPermissionsService {
    return groupsPermissionsService(this.instance);
  }

  myTeam(): MyTeamService {
    return myTeamService(this.instance);
  }

  password(): PasswordService {
    return passwordService(this.instance);
  }

  permissions(): PermissionsService {
    return permissionsService(this.instance);
  }
}

export const IdentityFactoryAPI = (params: IClientConfigProps): IdentityAPI => new IdentityAPI(params);
