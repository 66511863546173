import { AxiosInstance } from 'axios';

import { IPaginateDTO } from '@shared/dtos/IPaginateDTO';

import { Affiliation } from '@modules/affiliations/types/Affiliations/affiliations';
import {
  CreateHorseAffiliationRequest,
  DeleteHorseAffiliationByIdRequest,
  FindHorseAffiliationsPaginatedRequest,
  SendNotificationHorseAffiliationPaymentPendingRequest,
  UpdateHorseAffiliationRequest,
} from '@modules/affiliations/types/Affiliations/requests';

export class AssociationHorsesAffiliationsService {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Horses affiliations ----------

  public async create(data: CreateHorseAffiliationRequest): Promise<Affiliation> {
    const { associationHorseId: horseId, ...body } = data;

    const response = await this.instance.post(`/associations/horses/${horseId}/affiliations`, body);

    return response.data;
  }

  public async delete(data: DeleteHorseAffiliationByIdRequest): Promise<void> {
    const { associationHorseId, affiliationId } = data;

    await this.instance.delete(`/associations/horses/${associationHorseId}/affiliations/${affiliationId}`);
  }

  public async findPaginated(data: FindHorseAffiliationsPaginatedRequest): Promise<IPaginateDTO<Affiliation>> {
    const { associationHorseId: horseId, ...params } = data;

    const response = await this.instance.get(`/associations/horses/${horseId}/affiliations`, { params });

    return response.data;
  }

  public async update(data: UpdateHorseAffiliationRequest): Promise<Affiliation> {
    const { associationHorseId: horseId, affiliationId, ...body } = data;

    const response = await this.instance.put(`/associations/horses/${horseId}/affiliations/${affiliationId}`, body);

    return response.data;
  }

  async notifyPendingPayment(data: SendNotificationHorseAffiliationPaymentPendingRequest): Promise<void> {
    const { associationHorseId: horseId, affiliationId, ...body } = data;

    const url = `/associations/horses/${horseId}/affiliations/${affiliationId}/notify/pending-payment`;

    await this.instance.post(url, body);
  }
}

export const associationHorsesAffiliationsService = (instance: AxiosInstance): AssociationHorsesAffiliationsService =>
  new AssociationHorsesAffiliationsService(instance);
