import { AxiosInstance } from 'axios';

import { GenerateInvoiceDataSummary } from '@modules/invoices/types/Invoices/invoices';
import { GenerateInvoiceDataSummaryRequest } from '@modules/invoices/types/Invoices/requests';

export class InvoicesService {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // ---------- Invoices summary ----------

  async generateSummary(data: GenerateInvoiceDataSummaryRequest): Promise<GenerateInvoiceDataSummary> {
    const response = await this.instance.post(`/invoices/summary/generate`, data);

    return response.data;
  }
}

export const invoicesService = (instance: AxiosInstance): InvoicesService => new InvoicesService(instance);
